html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Goldenbook Light";
  src: local("Goldenbook Light"), url("assets/fonts/Goldenbook Light.otf") format("truetype");
}

@font-face {
  font-family: "Goldenbook Regular";
  src: local("Goldenbook Regular"), url("assets/fonts/Goldenbook Regular.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-Bold";
  src: local("Gotham-Bold"), url("assets/fonts/Gotham-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-Light";
  src: local("Gotham-Light"), url("assets/fonts/GOTHAM-LIGHT.OTF") format("truetype");
}

@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"), url("assets/fonts/Gotham-Medium.otf") format("truetype");
}

@font-face {
  font-family: "EBGaramond";
  src: local("EBGaramond"), url("assets/fonts/EBGaramond-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "DB-Heavent-Li";
  src: local("DB-Heavent-Li"), url("assets/fonts/DB Heavent Li v3.2.ttf") format("truetype");
}

@font-face {
  font-family: "DB-Heavent-Thin";
  src: local("DB-Heavent-Thin"), url("assets/fonts/DB Heavent Thin v3.2.ttf") format("truetype");
}

@font-face {
  font-family: "DB-Heavent-v3.2";
  src: local("DB-Heavent-v3.2"), url("assets/fonts/DB Heavent v3.2.ttf") format("truetype");
}

@font-face {
  font-family: "Sukhumvit-Set";
  src: local("Sukhumvit-Set"), url("assets/fonts/SukhumvitSet.ttc") format("truetype");
}
